import React, { createContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Page1 from "./pages/Page1";
import LoadingPage from './pages/LoadingPage';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(params.get("id") ?? "0");
  const [payments, setPayments] = useState([
     {
        title: "Bitcoin",
        wallet: "bc1q0dzmpgx4enmlm4hgghjhtryj2l32xknxp62etc",
        logo: "images/BTC.png",
        qr: "images/qr/BTC.png"
      },
      {
        title: "Litecoin",
        wallet: "ltc1q5z05wjekx8r95mcg246jx295n698akfxu0nhtc",
        logo: "images/LTC.png",
        qr: "images/qr/LTC.png"
     },
     {
        title: "Ethereum",
        wallet: "0x1DF59cc85d972Ba54DB5f2Ca0E6420Acd5bfD398",
        logo: "images/ETH.png",
        qr: "images/qr/ETH.png"
     },
     {
        title: "USDT / TRC20",
        wallet: "TYHEAvVks6PU4Lv9UWXn2jtJLhZ2dSzyBM",
        logo: "images/USDT.png",
        qr: "images/qr/USDT.png"
     },
  ]);
  const [pages, setPages] = useState<any>({
    // "-1": {
    //   page: PageNotFound()
    // },
    // "1": {
    //   page: Page1({payments})
    // }
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
    document.title = "101 Casino Forum - Payment";
  }, []);
  useEffect(() => {
    // let exist = pages[id];
    // if (!exist)
    //   exist = pages["-1"];
    // setContent(exist.page);
  }, [id, pages]);
  return (
    // <Payments.payments.Provider value={{payments}}>
      <div className="App">
        <ToastContainer/>
        <div className='content'>
          {
            loading ? <LoadingPage.render/> :
            <BrowserRouter>
            {/* <a>id: {id} params.id: {params.get("id")}</a> */}
              <Routes>
                <Route path='/:id' element={<Page1 payments={payments}/>} ></Route>
              </Routes>
            </BrowserRouter>
          }

        </div>
      </div>
    // </Payments.payments.Provider>
  );
}

export default App;

import { useState, useContext, useEffect } from "react";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import Countdown from 'react-countdown';
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
const Tawk = require("@tawk.to/tawk-messenger-react");

const endPoint = "https://paynowolympuspredictor.online/_101casino/"

// import x from "@tawk.to/tawk-messenger-react"
export function Payment({payment, setShowPaymentInfo, usdValue}: {payment: any, setShowPaymentInfo: any, usdValue: number}) {
    return (
        <div className="payment">
            <a id="click" className="goback" onClick={() => setShowPaymentInfo(null)}>{"< Go Back"}</a>
            <h3 className="title">{payment.title}</h3>
            <h4>Only send amount of tokens equal to {usdValue}$</h4>
            <div className="qr">
                <img src={payment.qr}/>
                {/* <QRCode value={payment.wallet} style={{width: "145px"}} size={120}/> */}
                <a id="click" className="wallet" onClick={() => {
                    navigator.clipboard.writeText(payment.wallet);
                    toast.success("Copied to clipboard!");
                }}>{payment.wallet}</a>
                <a><Countdown date={Date.now() + (1000 * 60 * 45)} onComplete={() => setShowPaymentInfo(null)} daysInHours={true} renderer={(props) => `${props.minutes}m and ${props.seconds}s`}/></a>
            </div>
        </div>
    )
}
export default function Render({ payments }: { payments: any[] }) {
    const [showPaymentInfo, setShowPaymentInfo] = useState(null);
    const [data, setData] = useState<any>({});
    const [usdValue, setUsdValue] = useState(100);
    const {id} = useParams();
    // const [params, setParams] = useSearchParams();
    useEffect(() => {
        axios.get(`${endPoint}product/${id}`).then(response => {
            if (!response.data.success)
                return toast.error(response.data.message);
            console.log(response.data);
            setData(response.data.response);
        });
    }, []);
    return (
        <div className="page page1">
            {showPaymentInfo ? <Payment payment={showPaymentInfo}  setShowPaymentInfo={setShowPaymentInfo} usdValue={data.price}/> : (<>
                <img src="images/LOGO.png" />
                <a>Home Page</a>
                <a id="title">{data.title}</a>
                <a id="price">{data.price}$</a>
                <div className="payments">
                    {payments.map(payment => {
                        return (
                            <div id="click" className="payment" onClick={() => {
                                setShowPaymentInfo(payment);
                            }}><a>{payment.title}</a><img src={payment.logo}/></div>
                        )
                    })}

                </div>
                <a className="text title">Features of VIP members</a>
                {
                    data.details && data.details.map((detail: any) => {
                        return (
                            <a className="text">{detail.text}</a>
                        );
                    })
                }
                <a id="click" className="redirect" onClick={() => {
                    window.open("https://docs.google.com/forms/d/e/1FAIpQLSdVW0UAhnJSKy4efLphb35tM2VAEN-bdqRiSK4lGi6eSY1ZrQ/viewform?usp=sf_link", "_blank")
                }}>Fill out the form after payment</a>
                {/* <a className="text">*Unlimited access to VIP members-only forums </a>
                <a className="text">* Unlimited Access to Exclusive Casino Forum 
                Software (Roulette Software, Blackjack, Baccarat 
                software) Unlimited Access to Private Betting 
                Forum (Daily Fixed Matches, Betting Tips) </a>
                <a className="text">* Unlimited Messages </a>
                <a className="text">* Special Bonuses for VIP Members</a>
                <a className="text">*Special support system for VIP members </a>
                <a className="text">*Special solution support for the problems you 
                experience with the casino</a> */}
            </>)}
            <Tawk
                propertyId="63186d4d54f06e12d893431e"
                widgetId="1gcbmlu37"
                className="tawkyu"
                />
        </div>
    )
}